import { Modal, Typography } from "antd";
import React, { useState } from "react";

const { Link } = Typography;

const GeneratedImagePixelTagModal = ({ appId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <React.Fragment>
      <Modal
        title={"Image Pixel Instructions"}
        width={"80%"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        centered
        footer={null}
      >
        <>
          <iframe
            src={`${process.env.REACT_APP_INTERNAL_MICROSERVICE_URL}/api/docs/html?filename=onboarding%2Fimage-pixel.md&appId=${appId}`}
            title="image-pixel"
            width="100%"
            height="800px"
            allow="clipboard-read; clipboard-write"
            style={{ border: "none" }}
          />
        </>
      </Modal>
      <div style={{ position: "relative", top: "80px" }}>
        <Link onClick={() => setIsModalVisible(true)}>Image Pixel</Link>
      </div>
    </React.Fragment>
  );
};

export default GeneratedImagePixelTagModal;
