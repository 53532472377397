import React, { useState } from "react";
import {
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Divider,
  Badge,
  Avatar,
  Tooltip,
} from "antd";
import { graphql, useQuery, useMutation } from "react-apollo";
import compose from "lodash/flowRight";
import { withRouter } from "react-router-dom";
import AvatarS3 from "../../shared/images/AvatarS3";
import { Auth } from "aws-amplify";
import CURRENT_USER from "../../../core/GraphQl/Queries/CURRENT_USER";
import SIGNOUT from "../../../core/GraphQl/Mutations/SIGNOUT";
import DELETE_NOTIFICATION from "../../../core/GraphQl/Mutations/DELETE_NOTIFICATION";
import DELETE_MANY_NOTIFICATIONS from "../../../core/GraphQl/Mutations/DELETE_MANY_NOTIFICATIONS";
import GET_NOTIFICATIONS from "../../../core/GraphQl/Queries/GET_NOTIFICATIONS";
import Breadcrumb from "./components/Breadcrumb";
import { isEmpty } from "lodash";
import {
  BellOutlined,
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RightCircleOutlined,
  UserOutlined,
  CloseCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { HasAccess } from "@permify/react-role";

const style = {
  emptyMenu: {
    background: "white",
    height: "40px",
    width: "200px",
    textAlign: "center",
    padding: "10px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
  },
};
const AppHeader = ({
  toggleSideBar,
  state: { collapsed },
  currentUser,
  history,
  location,
}) => {
  const { id, username, avatar, notifications, permission } = currentUser;

  let billing = permission.billing;

  const [isloading, setIsLoading] = useState(false);

  const { data } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      userId: id,
    },
  });

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS,
        variables: {
          userId: id,
        },
      },
    ],
    onCompleted: data => {
      setIsLoading(false);
    },
  });

  const [deleteManyNotifications] = useMutation(DELETE_MANY_NOTIFICATIONS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS,
        variables: {
          userId: id,
        },
      },
    ],
    onCompleted: data => {
      setIsLoading(false);
    },
  });

  const getNotification = data && data.notifications && data.notifications;

  const clearNotification = () => {
    if (!isEmpty(getNotification)) {
      deleteManyNotifications({
        variables: {
          ids: getNotification.map(n => n.id),
        },
      });
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        icon={<UserOutlined />}
        onClick={() => {
          history.push("/account");
        }}
        key="setting:1"
      >
        <Tooltip title="User Profile">{username}</Tooltip>
      </Menu.Item>
      {billing && (
        <Menu.Item
          icon={<DollarOutlined />}
          onClick={() => {
            history.push("/payment");
          }}
          key="setting:2"
        >
          <Tooltip title="Billing">Billing</Tooltip>
        </Menu.Item>
      )}

      <Menu.Item
        icon={<LogoutOutlined />}
        onClick={() => {
          Auth.signOut();
          window.location.reload();

          if (window && window.$chatwoot) {
            window.$chatwoot.reset();
          }
        }}
        key="setting:4"
      >
        <Tooltip title="Logout">Sign Out</Tooltip>
      </Menu.Item>
    </Menu>
  );

  const notificationMenu = isEmpty(getNotification) ? (
    <div style={style.emptyMenu}>You are all up to date!</div>
  ) : (
    <Menu>
      {getNotification.map((notification, i) => {
        const redirectRoute =
          notification.type &&
          `/${notification.type.toLowerCase()}${
            notification.featureID ? `/details/${notification.featureID}` : ""
          }`;

        // No blank notification message allowed.
        if (!notification.message) return false;

        return (
          <Menu.Item key={`notification:${i}`} disabled={isloading}>
            <div
              style={{
                height: "100%",
                width: "100%",
                overflow: "wrap",
                whiteSpace: "normal",
              }}
              onClick={() => {
                setIsLoading(true);

                const getPath =
                  history.location.pathname + history.location.search;

                notifications.splice(i, 1);
                history.push(getPath);

                deleteNotification({
                  variables: {
                    notificationID: notification.id,
                  },
                });

                if (redirectRoute) history.push(redirectRoute);
              }}
            >
              <Row>
                <Col span={2}>
                  <RightCircleOutlined />
                </Col>
                <Col offset={2} span={20}>
                  {notification.message}
                </Col>
              </Row>
            </div>
          </Menu.Item>
        );
      })}
      <Menu.Item
        key="clear-notifications"
        onClick={() => {
          clearNotification();
        }}
      >
        <Row>
          <Col span={2}>
            <CloseCircleOutlined />
          </Col>
          <Col offset={2} span={20}>
            Clear All Notifications
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );

  // TODO: When Menu is collapsed need to readjust when order changes
  return (
    <Layout.Header
      style={{
        background: "white",
        height: "100%",
        position: "sticky",
        top: 0,
        zIndex: 2,
      }}
    >
      <Row type="flex" align="middle" style={{ position: "relative" }}>
        <Col>
          {collapsed ? (
            <MenuUnfoldOutlined onClick={toggleSideBar} />
          ) : (
            <MenuFoldOutlined onClick={toggleSideBar} />
          )}
          <Divider
            type="vertical"
            style={{ marginLeft: "20px", height: "36px" }}
          />
        </Col>
        <Col>
          <HasAccess permissions={["default"]}>
            <Breadcrumb location={location} />
          </HasAccess>
        </Col>
        <HasAccess permissions={["default"]}>
          <Col style={{ position: "absolute", right: 0, top: 0 }}>
            <Row gutter={26} type={"flex"}>
              <Col>
                <Dropdown
                  overlay={notificationMenu}
                  trigger={["click"]}
                  placement="bottomCenter"
                  overlayStyle={{
                    paddingTop: "20px",
                    overflowY: "scroll",
                    maxHeight: "90vh",
                    width: "35vh",
                    position: "fixed",
                    top: "64px",
                    right: "60px",
                  }}
                >
                  <a className="ant-dropdown-link" href="#settings-dropdown">
                    <Badge count={getNotification && getNotification.length}>
                      <BellOutlined
                        style={{
                          color: "black",
                        }}
                      />
                    </Badge>
                  </a>
                </Dropdown>
              </Col>
              {/*<Col>
              <Icon
                style={{
                  color: "black",
                }}
                type="message"
              />
              </Col>*/}
              <Col>
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomCenter"
                  overlayStyle={{ paddingTop: "20px" }}
                >
                  <a className="ant-dropdown-link" href="#settings-dropdown">
                    {avatar ? (
                      <AvatarS3
                        imgKey={avatar.key}
                        avatarProps={{ size: "medium" }}
                      />
                    ) : (
                      <Avatar size="medium">{username.substring(0, 1)}</Avatar>
                    )}
                    <DownOutlined
                      style={{ padding: "3px", color: "#636363" }}
                    />
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </HasAccess>
      </Row>
      <div
        style={{
          height: 1,
          width: "100%",
          background: "#E8E8E8",
          position: "sticky",
          top: 64,
          zIndex: 2,
        }}
      />
    </Layout.Header>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser } }) => {
      return { currentUser: currentUser };
    },
  }),
  graphql(SIGNOUT, { name: "signOut" })
)(withRouter(AppHeader));
