import React, { useMemo } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";

const colorPicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "secondary";
  } else if (date && value < 1) {
    color = "danger";
  }
  return color;
};

const typePicker = (date, value) => {
  let color = "success";
  if (!date) {
    color = "default";
  } else if (date && value < 1) {
    color = "error";
  }
  return color;
};

const TagsMainController = ({
  dataMultiOrgTags,
  requestAssistance,
  location,
  children,
}) => {
  const params = new URLSearchParams(location.search);

  const formattedTags = useMemo(() => {
    if (!dataMultiOrgTags || !dataMultiOrgTags.eventsTargets) {
      return [];
    }

    const tags = dataMultiOrgTags.eventsTargets.map(tag => {
      const {
        transactions,
        pageViews,
        signups,
        name,
        id,
        tagUpdateDate,
        eventTags,
        orgs,
        isSignUps,
        createdAt,
      } = tag;
      const appId =
        eventTags && eventTags[0] && eventTags[0].appId && eventTags[0].appId[0]
          ? eventTags[0].appId[0]
          : "";
      return {
        id,
        name,
        advertiser: orgs && orgs[0] ? orgs[0].name : "",
        createdAt,
        measuredEvents: {
          pageViews,
          transactions,
          signups,
          isSignUps,
          date: tagUpdateDate
            ? moment(tagUpdateDate).format("MM/DD/YYYY HH:MM")
            : "Signal Unknown",
          colors: {
            transactions: typePicker(tagUpdateDate, transactions),
            pageViews: typePicker(tagUpdateDate, pageViews),
            signups: typePicker(tagUpdateDate, signups),
          },
          typeColors: {
            transactions: colorPicker(tagUpdateDate, transactions),
            pageViews: colorPicker(tagUpdateDate, pageViews),
            signups: colorPicker(tagUpdateDate, signups),
          },
        },
        appId,
      };
    });

    return tags.sort((a, b) => {
      const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
      const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
      return dateB - dateA;
    });
  }, [dataMultiOrgTags]);

  const filteredTags = useMemo(() => {
    let filtered = [...formattedTags];
    const nameSearch = (params.get("name") || "").toLowerCase();
    const appIdSearch = (params.get("appId") || "").toLowerCase();

    if (nameSearch) {
      filtered = filtered.filter(tag =>
        (tag.name || "").toLowerCase().includes(nameSearch)
      );
    }

    if (appIdSearch) {
      filtered = filtered.filter(tag =>
        (tag.appId || "").toLowerCase().includes(appIdSearch)
      );
    }

    return filtered;
  }, [formattedTags, params]);

  return React.cloneElement(children, {
    ...children.props,
    formattedTags: filteredTags,
    requestAssistance,
  });
};

export default withRouter(TagsMainController);
