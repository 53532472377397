import React from "react";
import { Collapse, Card } from "antd";

const { Panel } = Collapse;

const MetricPanel = ({
  categorizedValues,
  handleSelectedMetric,
  selectedCategory,
  titleFontStyle,
  selectedFontStyle,
  generateDisplayValue,
}) => {
  return (
    <Collapse
      className="custom-collapse"
      style={{
        border: "none",
      }}
      defaultActiveKey={[]}
    >
      {Object.keys(categorizedValues).map(categoryName => (
        <Panel header={categoryName} key={categoryName}>
          <div style={{ padding: "10px 0" }}>
            {categorizedValues[categoryName].map((item, index) => (
              <Card
                key={index}
                onClick={() => {
                  if (typeof handleSelectedMetric === "function") {
                    handleSelectedMetric(item);
                  }
                }}
                style={{
                  cursor: "pointer",
                  border: `1px solid black`,
                  marginBottom: "0.7em",
                  borderRadius: "6px",
                  borderLeft: `5px solid #1890ff`,
                }}
              >
                <div
                  key={`${categoryName}-${index}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    ...titleFontStyle,
                    ...(selectedCategory && selectedCategory.name === item.label
                      ? selectedFontStyle
                      : {}),
                  }}
                >
                  <span style={{ color: "gray" }}>
                    {item.label} {""}({item.subCategoryLogicalOperator}) {""}{" "}
                    <br />
                    <div>
                      {Array.isArray(item.value) ? (
                        <>
                          {item.value.map((item, index) => (
                            <p
                              key={`${index}${item.label}`}
                              style={{ marginBottom: "0px" }}
                            >
                              {item.label}
                            </p>
                          ))}
                        </>
                      ) : (
                        <>{generateDisplayValue(item.value, categoryName)}</>
                      )}
                    </div>
                  </span>
                </div>
              </Card>
            ))}
          </div>
        </Panel>
      ))}
    </Collapse>
  );
};

export default MetricPanel;
