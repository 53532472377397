import React, { useState } from "react";

// Ant Design
import { Table, Row, Col, Grid, Tag } from "antd";
import SelectedMetricList from "../components/SelectedMetricList";
import AudienceDetails from "../components/AudienceDetails";
import AudienceDetailsModal from "../components/Modals/AudienceDetailsModal.js";

import {
  demographicsAudienceTableColumn,
  demographicsAudienceTableColumnMobile,
} from "../utils/tableColumns";

import { isMobileScreen } from "../utils/mobileUtils";
import { useAudienceBuilderContext } from "../context/AudienceBuilderContext.js";

const DemographicsAudienceTable = props => {
  const {
    audienceDemographs,
    handleUpdateAudience,
    handleActivateAudience,
    handleFetchAudienceDemographs,
  } = props;

  const { setSetSelectedAudiences } = useAudienceBuilderContext();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { useBreakpoint } = Grid;

  const breakPoint = useBreakpoint();
  const [visbile, setVisible] = useState(false);
  const [metrics, setMetrics] = useState([]);

  const [audienceDetails, setAudienceDetails] = useState({});
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const defaultExpandable = {
    expandedRowRender: record => {
      const { metrics } = record;
      return (
        <>
          <Row style={{ minHeight: "700px" }} gutter={16}>
            <Col span={6}>
              <SelectedMetricList
                list={metrics}
                loading={false}
                disableDrag={true}
                displayOnly={true}
              ></SelectedMetricList>
            </Col>
            <Col span={18} style={{ height: "700px" }}>
              <AudienceDetails
                audienceDetails={record}
                record={record}
                handleUpdateAudience={handleUpdateAudience}
                handleActivateAudience={handleActivateAudience}
                handleFetchAudienceDemographs={handleFetchAudienceDemographs}
              />
            </Col>
          </Row>
        </>
      );
    },
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (record, selected, selectedRows) => {
      setSelectedRowKeys(record);
    },
    onSelect: (record, selected, selectedRows) => {
      setSetSelectedAudiences(selectedRows);
    },
  };

  const tableProps = isMobileScreen(breakPoint)
    ? {}
    : {
        rowSelection,
        expandable: defaultExpandable,
      };

  const tableColumns = isMobileScreen(breakPoint)
    ? demographicsAudienceTableColumnMobile
    : [
        ...demographicsAudienceTableColumn,
        {
          title: "Tags",
          key: "tags",
          dataIndex: "tags",
          render: (_, { tags }) => (
            <div style={{ width: 200, whiteSpace: "nowrap", overflow: "auto" }}>
              {tags &&
                tags
                  .sort((a, b) => a.localeCompare(b))
                  .map(tag => {
                    return (
                      <Tag
                        onClick={() => {
                          handleSelectState(tag);
                        }}
                        style={{
                          backgroundColor: isSelected(tag)
                            ? "#1890ff"
                            : "white",
                          color: isSelected(tag) ? "white" : "black",
                        }}
                        key={tag}
                      >
                        {tag}
                      </Tag>
                    );
                  })}
            </div>
          ),
        },
      ];
  const handleSelectState = x => {
    setSelected(prevSelected => {
      const isSelected = prevSelected.includes(x);
      if (isSelected) {
        const newSelected = prevSelected.filter(item => item !== x);
        const newData = audienceDemographs.filter(item =>
          newSelected.every(tag => item.tags.includes(tag))
        );
        setData(newData);
        return newSelected;
      } else {
        const newData = audienceDemographs.filter(item =>
          item.tags.includes(x)
        );
        setData(newData);
        return [...prevSelected, x];
      }
    });
  };

  const isSelected = id => selected.includes(id);

  const handleRowClick = record => {
    setAudienceDetails(record);
    setMetrics(record.metrics);
    setVisible(true);
  };

  const rowProps = record => ({
    onClick: () => handleRowClick(record),
  });

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <AudienceDetailsModal
        visible={visbile}
        audienceDetails={audienceDetails}
        metrics={metrics}
        handleCancel={handleCancel}
      />
      <Table
        {...tableProps}
        columns={tableColumns}
        styles={{ minHeight: "600px", height: "600px" }}
        dataSource={
          data && data.length > 0 && isSelected
            ? data.map(i => ({ ...i, key: i.id }))
            : audienceDemographs.map(i => ({ ...i, key: i.id }))
        }
        onRow={isMobileScreen(breakPoint) ? rowProps : null}
      />
    </>
  );
};

export default DemographicsAudienceTable;
