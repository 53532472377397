import gql from "graphql-tag";

const UPDATE_TASK = gql`
  mutation updateTask($data: TaskUpdateInput!, $where: TaskWhereUniqueInput!) {
    updateTask(data: $data, where: $where) {
      id
      name
      dueDate
      assigneeOrg {
        id
        name
      }
      assigneeUser {
        id
        name
      }
      requestType
      status
      channelPartner {
        id
        name
      }
      advertiser {
        id
        name
      }
      attachedFiles {
        id
        name
        url
      }
      attachedImages {
        id
        name
        url
      }
      relatedCampaigns {
        id
      }
    }
  }
`;

export default UPDATE_TASK;
