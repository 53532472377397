import React, { useRef, useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { UsergroupAddOutlined, CloseOutlined } from "@ant-design/icons";
import { mainTitleIcon } from "../../../shared/globalStyling/style";
import { Button, Card, Col, Row, Select, Input } from "antd";
import DocumentationAlert from "./components/DocumentationAlert";
import MainTable from "./components/MainTable";
import RequestAssistance from "./components/RequestAssitance";
import { withRouter } from "react-router-dom";

const styles = {
  search: {
    fontSize: "14px",
    width: "100%",
  },
  appIdSearch: {
    fontSize: "14px",
    width: "100%",
  },
  buttonS: {
    fontSize: "14px",
    width: "100%",
    height: "36px",
  },
};

const TagsMainView = ({
  formattedTags,
  history,
  location,
  requestAssistance,
}) => {
  const nameSearchRef = useRef(null);
  const appIdSearchRef = useRef(null);
  const params = new URLSearchParams(location.search);
  const status = params.get("status") ? params.get("status") : "live";

  // search refactor
  const [nameValue, setNameValue] = useState(params.get("name") || "");
  const [appIdValue, setAppIdValue] = useState(params.get("appId") || "");

  const onSearch = () => {
    const name =
      nameSearchRef.current && nameSearchRef.current.input
        ? nameSearchRef.current.input.value
        : "";
    const appId =
      appIdSearchRef.current && appIdSearchRef.current.input
        ? appIdSearchRef.current.input.value
        : "";

    if (params.get("name")) params.delete("name");
    if (name) params.append("name", name);

    if (params.get("appId")) params.delete("appId");
    if (appId) params.append("appId", appId);

    history.push({
      pathname: location.pathname,
      search: `?${params.toString()}`,
    });
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const hasActiveFilters = () => {
    return nameValue || appIdValue;
  };

  const handleClear = () => {
    setNameValue("");
    setAppIdValue("");
    if (nameSearchRef.current && nameSearchRef.current.input) {
      nameSearchRef.current.input.value = "";
    }
    if (appIdSearchRef.current && appIdSearchRef.current.input) {
      appIdSearchRef.current.input.value = "";
    }

    params.delete("name");
    params.delete("appId");
    history.push({
      pathname: location.pathname,
      search: params.toString() ? `?${params.toString()}` : "",
    });
  };

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Tags"}
        titleIcon={<UsergroupAddOutlined style={mainTitleIcon} />}
        rightContent={
          <RequestAssistance onRequestAssistance={requestAssistance} />
        }
      />
      <PageContentLayout>
        <PageHeaderLayout
          title={"Tags"}
          rightContent={
            <Row align="middle" justify="center" gutter={[16, 16]}>
              <Col>
                <Select
                  defaultValue={"live"}
                  value={status}
                  style={{ width: "120px" }}
                  onChange={val => {
                    if (status) {
                      params.delete("status");
                    }
                    params.append("status", val);
                    history.push({
                      pathname: location.pathname,
                      search: `?${params.toString()}`,
                    });
                  }}
                >
                  <Select.Option value="live">LIVE</Select.Option>
                  <Select.Option value="disabled">DISABLED</Select.Option>
                </Select>
              </Col>
              <Col>
                <Input
                  placeholder="Search by Tag ID"
                  style={styles.appIdSearch}
                  ref={appIdSearchRef}
                  onKeyPress={handleKeyPress}
                  value={appIdValue}
                  onChange={e => setAppIdValue(e.target.value)}
                />
              </Col>
              <Col>
                <Input.Search
                  placeholder="Search by Name"
                  ref={nameSearchRef}
                  style={styles.search}
                  onSearch={onSearch}
                  onKeyPress={handleKeyPress}
                  value={nameValue}
                  onChange={e => setNameValue(e.target.value)}
                />
              </Col>
              <Col>
                <Button
                  style={styles.buttonS}
                  shape="round"
                  type="default"
                  onClick={handleClear}
                  disabled={!hasActiveFilters()}
                  icon={<CloseOutlined />}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          }
        />
        <Row
          align="middle"
          justify="center"
          style={{ background: "white", padding: "10px" }}
        >
          <Col xl={18} lg={24} offset={1}>
            <DocumentationAlert />
          </Col>
          <Col xl={4} lg={24} offset={1}>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                history.push(`/tags/manager`);
              }}
            >
              Create New Tag
            </Button>
          </Col>
        </Row>
        <Card>
          <MainTable tags={formattedTags} />
        </Card>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(TagsMainView);
