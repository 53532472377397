import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";
import moment from "moment";
import { usePermify } from "@permify/react-role";

const { Text } = Typography;

const CampaignRequestorBudget = ({ form }) => {
  const { currentUserOrg } = useCampaignRequestorContext();
  const { isAuthorized } = usePermify();
  const [canEdit, setCanEdit] = useState(false);
  const advertiserId = form.getFieldValue("advertiserId");
  const startDate = form.getFieldValue("startDate");
  const endDate = form.getFieldValue("endDate");

  const handleStartDateChange = value => {
    let updatedEndDate = endDate;
    let updatedStartDate = startDate;
    if (value) {
      if (value.isAfter(endDate)) {
        updatedEndDate = null;
      }
    }

    form.setFieldsValue({ startDate: value ? value : null });
    updatedStartDate = value ? value : null;
    // Check first if the end date is set and end date is greater than 2 days after start date and if so don't change end date
    if (endDate && moment(endDate).isAfter(moment(value).add(2, "days"))) {
      form.setFieldsValue({
        startDate: updatedStartDate,
        endDate: updatedEndDate,
        numberOfDays: updatedEndDate.diff(updatedStartDate, "days"),
      });

      return;
    }

    // Set end date 2 days after start date
    if (value) {
      updatedEndDate = moment(value).add(2, "days");
      form.setFieldsValue({
        startDate: updatedStartDate,
        endDate: updatedEndDate,
        numberOfDays: updatedEndDate.diff(updatedStartDate, "days"),
      });
    }
  };

  const handleEndDateChange = value => {
    let updatedEndDate = endDate;
    let updatedStartDate = startDate;
    if (value) {
      if (value.isBefore(startDate)) {
        updatedStartDate = null;
      }
    }

    form.setFieldsValue({ endDate: value ? value : null });
    updatedEndDate = value ? value : null;
    // Check first if the start date is set and end date is greater than 2 days after start date and if so don't change start date
    if (startDate && moment(value).isAfter(moment(startDate).add(2, "days"))) {
      form.setFieldsValue({
        startDate: updatedStartDate,
        endDate: updatedEndDate,
        numberOfDays: updatedEndDate.diff(updatedStartDate, "days"),
      });

      return;
    }

    // Set start date 2 days before end date
    if (value) {
      updatedStartDate = moment(value).subtract(2, "days");
      form.setFieldsValue({
        startDate: updatedStartDate,
        endDate: updatedEndDate,
        numberOfDays: updatedEndDate.diff(updatedStartDate, "days"),
      });
    }
  };

  const handleNumberOfDaysChange = e => {
    // Update end date based on number of days
    const updatedStartDate = startDate;
    const updatedEndDate = moment(startDate).add(
      parseInt(e.target.value),
      "days"
    );
    form.setFieldsValue({
      startDate: updatedStartDate,
      endDate: updatedEndDate,
    });
  };

  const disabledEndDate = date => {
    if (!startDate) {
      // If no start date is selected, disable all dates
      return true;
    }

    // Disabled if date is before start date
    if (date.isBefore(startDate)) {
      return true;
    }

    const start = moment(startDate).startOf("day");
    const end = moment(startDate)
      .add(2, "days")
      .endOf("day");

    return date.isBetween(start, end, null, "[]");
  };

  useEffect(() => {
    const checkPermission = async () => {
      const authorized = await isAuthorized(
        ["isTrafficker", "isProcessor"],
        []
      );
      setCanEdit(authorized);
    };

    checkPermission();
  }, [isAuthorized]);

  return (
    <>
      <Card id="requester-duration-budget" title="DURATION & BUDGET">
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col span={10}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text>Campaign Budget</Text>
                <Text type="secondary">
                  Maximum amount campaign spends over duration
                </Text>
                <Form.Item
                  name="budget"
                  rules={[
                    {
                      required: true,
                      message: "Please input a budget",
                    },
                    () => ({
                      validator(_, value) {
                        if (value >= 1 || value === "") {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please input a valid budget")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    addonBefore="$USD"
                    style={{ width: "50%" }}
                    type="number"
                    min={0}
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col span={7}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text>CPM</Text>
                <Text type="secondary">Rate per 1,000 impressions</Text>
                <Form.Item
                  name="cpm"
                  rules={[
                    {
                      required: true,
                      message: "Please input a CPM",
                    },
                    () => ({
                      validator(_, value) {
                        if (value >= 0 || value === "") {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please input a valid CPM")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    style={{ width: "100px" }}
                    type="number"
                    min={0}
                    disabled={
                      currentUserOrg &&
                      currentUserOrg.id === advertiserId &&
                      !canEdit
                    }
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col span={7}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text>Impressions</Text>
                <Text type="secondary">Campaign total </Text>
                <Input
                  style={{ width: "100px" }}
                  disabled
                  value={(
                    form.getFieldValue("impressions") || 0
                  ).toLocaleString()}
                />
              </Space>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text>Start Date</Text>
                    <Text type="secondary">Leave blank for ASAP </Text>
                    <Form.Item
                      name="startDate"
                      rules={[
                        {
                          required: true,
                          message: "Please input a start date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={handleStartDateChange}
                        disabledDate={date =>
                          !canEdit
                            ? date.isBefore(moment().startOf("day"))
                            : false
                        }
                      />
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={6}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text>End Date</Text>
                    <Text type="secondary">Date campaign will end</Text>
                    <Form.Item
                      name="endDate"
                      rules={[
                        {
                          required: true,
                          message: "Please input an end date",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={handleEndDateChange}
                        disabledDate={disabledEndDate}
                      />
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={10}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text>Number of Days</Text>
                    <Text type="secondary">
                      Enter a number to adjust the end date by that many days.
                    </Text>
                    <Form.Item
                      name="numberOfDays"
                      rules={[
                        {
                          required: true,
                          message: "Please input a number of days",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        style={{ width: "100px" }}
                        disabled={!canEdit}
                        min={2}
                        onChange={handleNumberOfDaysChange}
                      />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Space>
      </Card>
    </>
  );
};

export default CampaignRequestorBudget;
