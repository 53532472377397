import React from "react";
import {
  Card,
  Button,
  Dropdown,
  Menu,
  Spin,
  Divider,
  Space,
  Typography,
} from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { getColorTag } from "./../utils/getColorRange";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CombinedCard from "./CombinedCard";

// Constants
import { SegmentType } from "../constants/constants";
import { matchOptions } from "../../../../core/utils/constants/demographicConstants";
import MetricPanel from "./Modals/SelectedMetricPanel";

const { Title, Text } = Typography;

const SelectedMetricList = props => {
  const {
    list,
    loading,
    titleFontStyle,
    selectedCategory,
    selectedFontStyle,
    handleSelectedMetric,
    removeAudienceMetric,
    updateAudienceMetric,
    handleDragAndDrop,
    separateCombinedItems,
    disableDrag,
  } = props;

  const generateMenuItem = (
    key,
    text,
    handler,
    item,
    isSubCategory = false,
    index = -1
  ) => (
    <Menu.Item
      key={isSubCategory ? `SUB_CAT_${key}` : key}
      onClick={e => handler(e, item, isSubCategory)}
      disabled={index === 0}
    >
      {text}
    </Menu.Item>
  );

  const menu = (item, index) => (
    <Menu>
      {generateMenuItem(
        "AND",
        "Set to AND",
        handleSetOperator,
        item,
        true,
        index
      )}
      {generateMenuItem(
        "OR",
        "Set to OR",
        handleSetOperator,
        item,
        true,
        index
      )}
      {generateMenuItem("remove", "Remove", handleRemove, item)}
    </Menu>
  );

  const handleSetOperator = (e, item, isSubCategory) => {
    e.domEvent.stopPropagation(); // Prevent the onClick of Card from being triggered
    if (updateAudienceMetric) {
      const data = {
        ...item,
        logicalOperator: isSubCategory ? item.logicalOperator : e.key,
        subCategoryLogicalOperator: isSubCategory
          ? e.key.replace("SUB_CAT_", "")
          : item.subCategoryLogicalOperator,
      };

      updateAudienceMetric(data);
    }
  };

  const handleRemove = (e, item) => {
    e.domEvent.stopPropagation(); // Prevent the onClick of Card from being triggered
    if (removeAudienceMetric) {
      removeAudienceMetric(item);
    }
  };

  const generateDisplayValue = (finaldisplayValue, categoryName) => {
    switch (list[0].operator) {
      case matchOptions[2].value:
        return (
          <Space direction="vertical">
            <Text>{`${matchOptions[2].label} ${finaldisplayValue}`}</Text>
          </Space>
        );
      case matchOptions[3].value:
        return (
          <Space direction="vertical">
            <Text>{`${matchOptions[3].label} ${finaldisplayValue}`}</Text>
          </Space>
        );
      case matchOptions[4].value:
        return (
          <Space direction="vertical">
            <Text>{`${matchOptions[4].label} ${finaldisplayValue}`}</Text>
          </Space>
        );
      case matchOptions[5].value:
        return (
          <Space direction="vertical">
            <Text>{`${matchOptions[5].label} ${finaldisplayValue}`}</Text>
          </Space>
        );
      default:
        return (
          <Space direction="vertical">
            <Text>{`${finaldisplayValue}`}</Text>
          </Space>
        );
    }
  };

  const categorizedValues = list
    .filter(item => item.segmentType === SegmentType.Include)
    .reduce((acc, item) => {
      const {
        name,
        operator,
        label,
        value,
        dataType,
        subCategoryLogicalOperator,
        categoryName,
        isCombined,
      } = item;

      if (!acc[categoryName]) {
        acc[categoryName] = [];
      }

      acc[categoryName].push({
        name,
        operator,
        label,
        value,
        dataType,
        subCategoryLogicalOperator,
        isCombined,
      });

      return acc;
    }, {});

  return (
    <Spin spinning={loading}>
      <DragDropContext onDragEnd={handleDragAndDrop} isCombineEnabled={true}>
        <Title level={5}>Included Segments</Title>
        {removeAudienceMetric && (
          <Text>
            Drag and drop segment cards to combine them into an "or" statement
          </Text>
        )}

        <div style={{ marginTop: "0.5em", minHeight: "20em" }}>
          <Droppable droppableId="include" type="group" isCombineEnabled={true}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  height: "100%", // Ensure a minimum height even when empty
                  backgroundColor: snapshot.isDraggingOver
                    ? "lightblue"
                    : "white",
                  transition: "background-color 0.2s ease",
                }}
              >
                <MetricPanel
                  categorizedValues={categorizedValues}
                  handleSelectedMetric={handleSelectedMetric}
                  selectedCategory={selectedCategory}
                  titleFontStyle={titleFontStyle}
                  selectedFontStyle={selectedFontStyle}
                  generateDisplayValue={generateDisplayValue}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <Divider />
        <Title level={5}>Excluded Segments</Title>
        {removeAudienceMetric && (
          <Text>
            Selected audience segments excluded from targeting will appear here
          </Text>
        )}
        <div style={{ minHeight: "20em" }}>
          <Droppable droppableId="exclude" type="group">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  minHeight: "20em",
                  height: "100%", // Ensure a minimum height even when empty
                  backgroundColor: snapshot.isDraggingOver
                    ? "lightblue"
                    : "white",
                  transition: "background-color 0.2s ease",
                }}
              >
                {list
                  .filter(item => item.segmentType === SegmentType.Exclude)
                  .map((item, index) => {
                    let {
                      name,
                      operator,
                      label,
                      value,
                      dataType,
                      subCategoryLogicalOperator,
                      categoryName,
                      isCombined,
                    } = item;

                    const finaldisplayValue =
                      dataType === "boolean" ? (value ? "Yes" : "No") : value;

                    return (
                      <Draggable
                        draggableId={item.uniqueId}
                        index={index}
                        key={item.uniqueId}
                        isDragDisabled={disableDrag}
                      >
                        {provided => (
                          <div
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            {isCombined ? (
                              <CombinedCard
                                item={item}
                                index={index}
                                separateCombinedItems={separateCombinedItems}
                                removeAudienceMetric={removeAudienceMetric}
                              />
                            ) : (
                              <Card
                                key={index + name}
                                onClick={() => {
                                  if (
                                    typeof handleSelectedMetric === "function"
                                  ) {
                                    handleSelectedMetric(item);
                                  }
                                }}
                                style={{
                                  cursor: "pointer",
                                  border: `1px solid black`,
                                  marginBottom: "0.7em",
                                  borderRadius: "6px",
                                  borderLeft: `5px solid ${getColorTag(
                                    operator
                                  )}`,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    ...titleFontStyle,
                                    ...(selectedCategory &&
                                    selectedCategory.name === name
                                      ? selectedFontStyle
                                      : {}),
                                  }}
                                >
                                  <span>
                                    {label}
                                    {index > 0 &&
                                      ` (${subCategoryLogicalOperator})`}
                                  </span>
                                  {removeAudienceMetric && (
                                    <Dropdown
                                      overlay={menu(item, index)}
                                      trigger={["click"]}
                                    >
                                      <Button
                                        type="text"
                                        icon={<EllipsisOutlined />}
                                        onClick={e => e.stopPropagation()} // Prevent the onClick of Card from being triggered
                                      />
                                    </Dropdown>
                                  )}
                                </div>
                                <div>
                                  {Array.isArray(finaldisplayValue) ? (
                                    <>
                                      {finaldisplayValue.map((item, index) => (
                                        <p
                                          key={`${index}${item.label}`}
                                          style={{ marginBottom: "0px" }}
                                        >
                                          {item.label}
                                        </p>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      {generateDisplayValue(
                                        finaldisplayValue,
                                        categoryName
                                      )}
                                    </>
                                  )}
                                </div>
                                <div>
                                  <Text
                                    style={{
                                      color: "rgba(0,0,0,0.45",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {categoryName}
                                  </Text>
                                </div>
                              </Card>
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </Spin>
  );
};

export default SelectedMetricList;
